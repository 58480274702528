import "./offer-form.scss";
import template from "./offer-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { Offer, OfferItem } from "../../../clients/api-client/types.js";
import { FormDialog } from "../../common/form-dialog/form-dialog.js";
import { OfferModuleForm } from "../offer-module-form/offer-module-form.js";
import { ObjectHelper } from "@incinity/hiyo/object-helper.js";
import { OfferConnectorForm } from "../offer-connector-form/offer-connector-form.js";
import { OfferServiceForm } from "../offer-service-form/offer-service-form.js";
import { TemplatePreview } from "../../common/template-preview/template-preview.js";

@component(template)
export class OfferForm extends FormDialog {

    // Properties
    public top: number;
    public discount: number = 10;
    public offer: Offer;

    public onAttach(): void {
        // Set data or empty project to offer
        this.offer = ObjectHelper.copy(this.options.data) || {};
    }

    public addModule(): void {
        // Module dialog
        let dialog = new OfferModuleForm(this.context, {
            data: this.offer
        });

        // Handle data
        dialog.onSubmit = (item: OfferItem) => {
            // Add new item
            this.offer.items ? this.offer.items.push(item) : this.offer.items = [item];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeModule(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove module by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public addConnector(): void {
        // Connector dialog
        let dialog = new OfferConnectorForm(this.context, {
            data: this.offer
        });

        // Handle data
        dialog.onSubmit = (item: OfferItem) => {
            // Add new item
            this.offer.items ? this.offer.items.push(item) : this.offer.items = [item];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeConnector(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove connector by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public addService(): void {
        // Service dialog
        let dialog = new OfferServiceForm(this.context, {
            data: this.offer
        });

        // Handle data
        dialog.onSubmit = (item: OfferItem) => {
            // Add new item
            this.offer.items ? this.offer.items.push(item) : this.offer.items = [item];

            // Redraw
            this.render();
        }

        // Show dialog
        dialog.showModal();
    }

    public removeService(i: number): void {
        // Cancel bubbles
        event.stopPropagation();

        // Remove service by index
        this.offer.items.splice(i, 1);

        // Redraw
        this.render();
    }

    public setDatasetCount(i: number, j: number, count: number): void {
        // Update count
        this.offer.items[i].datasources[j].count = Number(count);

        // Render
        this.render();
    }

    public setServiceCount(i: number, count: number): void {
        // Update count
        this.offer.items[i].count = Number(count);

        // Render
        this.render();
    }

    public setScroll(top: number): void {
        // Save scroll top position to restore if content is rendered again
        this.top = top;
    }

    public showTemplatePreview(): void {
        // Create preview dialog
        let preview = new TemplatePreview(this.context, {
            key: "OfferPdf",
            data: {
                pricelist: this.context.data.pricelist.version,
                discount: this.discount,
                ...this.offer,
                ...this.form.getData(true)
            }
        });

        // Show
        preview.showModal();
    }

    public render(): void {
        // Reset summary
        this.offer.summary = {
            licenses: 0,
            services: 0,
            maintenance: 0,
            discount: 0,
            total: 0,
            partnerTotal: 0
        }

        // Calculate prices
        for (let item of this.offer.items || []) {
            // Sum up extra item price
            item.extra = item.datasources?.map(x => x.price * x.count).reduce((a, b) => a + b, 0) || 0;

            // Add licenses
            if (item.type == "Module" || item.type == "Connector") {
                this.offer.summary.licenses += item.price + item.extra;
            }

            // Add services
            if (item.type == "Service") {
                this.offer.summary.services += (item.price * item.count) || 0;
            }

            // Add total
            this.offer.summary.total += ((item.price * item.count) || 0) + item.extra;
        }

        // Find maintenance service if available
        let maintenance = this.offer.items?.find(x => x.percent);

        // Calculate maintenance, discount and total prices
        this.offer.summary.maintenance = maintenance ? this.offer.summary.licenses * (maintenance.count * 0.12) : 0;
        this.offer.summary.total += this.offer.summary.maintenance;
        this.offer.summary.discount = (this.offer.summary.total > 0) ? -this.offer.summary.total * (this.discount / 100) : 0; // Avoid -0
        this.offer.summary.partnerTotal = this.offer.summary.total + this.offer.summary.discount;

        // Call render
        super.render();

        // Keep scroll top in same position
        this.querySelector("article").scrollTo({ top: this.top });
    }

    public async trySubmit(){
        // Merge offer data with form
        this.offer = {
            pricelist: this.context.data.pricelist.version,
            discount: this.discount,
            ...this.offer,
            ...this.form.getData(true)
        }

        // Update call?
        if (this.offer._id) {
            await this.context.api.updateResource(`offers/${this.offer._id}`, this.offer);
        }
        // Create call
        else {
            await this.context.api.createResource("offers", this.offer);
        }
    }

}