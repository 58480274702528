import "./incinet-app.scss";
import template from "./incinet-app.hbs";
import messagesEn from "../../../messages/messages.en.json";
import { component, events } from "@incinity/hiyo/decorators.js";
import { Component } from "@incinity/hiyo/component.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { User } from "../../../clients/api-client/types.js";
import { Log } from "@incinity/hiyo/log.js";
import { Messages } from "@incinity/hiyo/messages.js";
import { Templates } from "@incinity/hiyo/templates.js";
import { IncinetRouter } from "../../../router/incinet-router.js";

export const USER_TOKEN_KEY = "Hiyo.userToken";

@component(template, true)
export class IncinetApp extends Component<IncinetContext> {

    public async onCreate() {
        Log.i("Welcome to Incinet. The world of fantasy🤡");

        // Add localization
        Messages.add("en", messagesEn);
        Messages.add("cs", messagesEn);
        Messages.guessLang();

        // Register Handlebars helpers
        Templates.registerHelpers();

        // Incinet app component must create an instance of IncinetContext that
        // will be shared across all components
        this.context = new IncinetContext();
        Log.i(`${this.constructor.name}: Application context created`);
    }

    public async onRender(): Promise<void> {
        //await SystemHelper.sleep(200);

        // Fade in
        this.style.opacity = "1";

        // Router
        let router = new IncinetRouter();
        router.createRoutes();
        router.doRouting();
    }

    public async login(user: User): Promise<void> {
        Log.i(`${this.constructor.name}: Received a "login" event, will proceed to enter the app`);

        // Assign user
        this.context.user = user;

        // Store user token to local storage
        localStorage.setItem(USER_TOKEN_KEY, user.token);

        // Set store token
        this.context.api.enableBasicAuthorization(user.token);

        // Synchronize data
        await this.feed();

        // Redraw
        this.render();
    }

    public logout(): void {
        // Set no user
        this.context.user = null;

        // Remove stored user token
        localStorage.removeItem(USER_TOKEN_KEY);

        // Redraw
        this.render();
    }

    public async feed(): Promise<void> {
        // Load pricelist
        this.context.data.pricelist = await this.context.api.getResource(`pricelists/current`);
    }

    public async load(): Promise<any> {
        // Get user token stored in local storage
        let token = localStorage.getItem(USER_TOKEN_KEY);

        // Token found, so we need to verify its validity
        if (token) {
            // Set store token
            this.context.api.enableBasicAuthorization(token);

            // Verify user using store token
            try {
                this.context.user = await this.context.api.getLoggedUser();
                Log.i(`${this.constructor.name}: User ${this.context.user.name} restored (stored token still valid)`);

                // Synchronize data
                await this.feed();
            }
            catch (e) {
                Log.i(`User token is invalid or has expired`);
                console.info(e);
            }
        }

        // We need user to log in
        if (!this.context.user) {
            Log.i(`User token not found or expired, will need to login again`);
        }
    }

}