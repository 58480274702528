import { ApiClientOptions, Info, PagedData, User } from "./types.js";
import { Http } from "@incinity/hiyo/http.js";
import { Log } from "@incinity/hiyo/log.js";
import { SystemHelper } from "@incinity/hiyo/system-helper.js";

export class ApiClient {

    // Properties
    public options: ApiClientOptions;
    public http: Http;

    public constructor(options: ApiClientOptions) {
        this.options = options;

        // Http client with 30 seconds timeout
        this.http = new Http({
            timeout: 30000
        });
    }

    public enableBasicAuthorization(token: string) {
        this.options.accessToken = token;

        // Set authorization header to each api call
        this.http.options.authorization = `Basic ${token}`;

        Log.i(`ApiClient: Basic authorization enabled`);
    }

    public enableUserUuid(uuid: string) {
        // Set uuid header to each service call
        this.http.options.uuid = uuid;

        Log.i(`ApiClient: User UUID set to ${uuid}`);
    }

    public async getInfo(): Promise<Info> {
        return await this.http.request("GET", `${this.options.host}/api/info`);
    }

    public async getConfig(key: string): Promise<any> {
        return await this.http.request("GET", `${this.options.host}/api/config?key=${key}`);
    }

    public async postCalculation(calculation: any): Promise<Info> {
        return await this.http.request("POST", `${this.options.host}/api/calculations`, {
            data: calculation
        });
    }

    public async getLoggedUser(): Promise<User> {
        return await this.http.request("GET", `${this.options.host}/api/auth`);
    }

    public async loginUser(username: string, password: string, code?: string): Promise<User> {
        // Send username and password to standard auth endpoint
        let user = await this.http.request("POST", `${this.options.host}/api/auth`, {
            data: {
                username: username,
                password: password,
                code: code
            }
        });

        // Create unique client id
        user.uuid = SystemHelper.newUuid();

        return user;
    }

    public async logoutUser(): Promise<User> {
        return await this.http.request("DELETE", `${this.options.host}/api/auth`);
    }

    public async getUser(id: string): Promise<User> {
        return await this.http.request("GET", `${this.options.host}/api/users/${id}`);
    }

    public async getUsers(): Promise<PagedData<User>> {
        return await this.http.request("GET", `${this.options.host}/api/users`);
    }

    public async getPartners(query?: string): Promise<PagedData> {
        let url = `${this.options.host}/api/partners`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request("GET", url);
    }

    public async getResource(resource: string, query?: string): Promise<PagedData | any> {
        let url = `${this.options.host}/api/${resource}`;

        // Custom query
        if (query) {
            url += `?${query}`;
        }

        return await this.http.request("GET", url);
    }

    public async createResource(resource: string, data?: any): Promise<void> {
        let url = `${this.options.host}/api/${resource}`;

        return await this.http.request("POST", url, {
            data: data
        });
    }

    public async updateResource(resource: string, data?: any): Promise<void> {
        let url = `${this.options.host}/api/${resource}`;

        return await this.http.request("PUT", url, {
            data: data
        });
    }

    public async deleteResource(resource: string): Promise<void> {
        let url = `${this.options.host}/api/${resource}`;

        return await this.http.request("DELETE", url);
    }
}
