import "./offer-service-form.scss";
import template from "./offer-service-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { FormDialog } from "../../common/form-dialog/form-dialog.js";
import { OfferItem } from "../../../clients/api-client/types.js";

@component(template)
export class OfferServiceForm extends FormDialog {

    // Properties
    public services: any[];

    public onAttach(): void {
        // Filter out not selected services
        this.services = this.context.data.pricelist.services.filter(x => !this.options.data.items?.map(y => y.code).includes(x.code));

        // Sort out
        this.services.sort((a, b) => a.name.localeCompare(b.name));
    }

    public search(term: string): void {
        // Show or hide matching items
        this.querySelectorAll("pinko-section").forEach((e: HTMLElement) => {
            e.style.display = (term == null || e.textContent.toLowerCase().includes(term.toLowerCase())) ? "block" : "none";
        });

        // Reset scroll position
        //this.querySelector("article").scrollTo({ top: 0 });
    }

    public select(i: number): void {
        // Get module from pricelist
        let module = this.services[i];

        // Create offer item from module
        let item: OfferItem = {
            type: "Service",
            code: module.code,
            name: module.name,
            price: module.price,
            count: 1,
            percent: module.percent,
            unit: module.unit,
        }

        // Submit with module
        this.submit(item);
    }
}