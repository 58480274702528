import "./offer-preview.scss";
import template from "./offer-preview.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoPreview } from "@incinity/pinko/components/pinko-preview/pinko-preview.js";
import { PinkoTabs } from "@incinity/pinko/components/pinko-tabs/pinko-tabs.js";
import { PinkoDropdown } from "../../../@incinity/pinko/components/pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "../../../@incinity/pinko/components/pinko-dropdown/types.js";
import { Messages } from "@incinity/hiyo/messages.js";
import { Partner } from "../../../clients/api-client/types.js";
import { TemplatePreview } from "../../common/template-preview/template-preview.js";
import { OfferForm } from "../offer-form/offer-form.js";

@component(template, true)
export class OfferPreview extends PinkoPreview<IncinetContext> {

    // Properties
    public partner: Partner;
    public activities: any[];

    // Components
    public tabs: PinkoTabs;

    public onCreate(): void {
        // Create tabs
        this.tabs = new PinkoTabs(this.context, {
            items: [
                {
                    name: "General",
                    label: "General",
                    selected: true
                },
                {
                    name: "Activity",
                    label: "Activity"
                }
            ]
        });
    }

    public selectStatus(target: HTMLElement): void {
        // Stop propagation
        event.stopPropagation();

        // Create component
        let menu = new PinkoDropdown(this.context, {
            start: "BottomRight",
            anchor: "TopRight",
            groups: [
                {
                    name: "Main",
                    items: Object.keys(Messages.getStore().enums.OfferStatus).map(x => ({
                        name: x,
                        label: x,
                        selected: (x == this.options.data.status)
                    }))
                }
            ]
        });

        // Change language
        menu.onSelect = async (item: DropdownItem): Promise<void> => {
            // Show loader
            this.lock();

            // Update status
            await this.context.api.updateResource(`offers/${this.options.data._id}/status`, {
                status: item.name
            });

            // Hide loader and redraw
            this.unlock();

            // Reload
            await this.reload();

            // OnUpdate handler
            this.onUpdate();
        }

        // Show menu
        menu.show(target);
    }

    public showForm(): void {
        // Create dialog
        let form = new OfferForm(this.context, {
            data: {
                ...this.options.data,
                partner: this.options.data?.partner ? { [this.options.data.partner._id]: this.options.data.partner.name } : null
            }
        });

        // Reload table on submit
        form.onSubmit = async () => {
            // Reload self
            await this.reload();

            // OnUpdate handler
            this.onUpdate();
        }

        // Show modal
        form.showModal();
    }

    public showTemplatePreview(): void {
        // Create preview dialog
        let preview = new TemplatePreview(this.context, {
            key: "OfferPdf",
            data: this.options.data
        });

        // Show
        preview.showModal();
    }

    public async load(): Promise<void> {
        // Load detail
        this.options.data = await this.context.api.getResource(`offers/${this.options.data._id}`);

        // Load partner
        this.partner = await this.context.api.getResource(`partners/${this.options.data.partner._id}`);

        // Load activities
        this.activities = await this.context.api.getResource("activity", `documentId=${this.options.data._id}&type=OfferUpdated,OfferStatusUpdated&sort=timestamp:desc`);
    }

}