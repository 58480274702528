import "./offer-browser.scss";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoFilter } from "@incinity/pinko/components/pinko-filter/pinko-filter.js";
import { PinkoSelect } from "@incinity/pinko/components/pinko-select/pinko-select.js";
import { SearchItem } from "@incinity/pinko/components/pinko-search/types.js";
import { PinkoTable } from "@incinity/pinko/components/pinko-table/pinko-table.js";
import { OfferPreview } from "../offer-preview/offer-preview.js";
import { TableAction, TableRow } from "@incinity/pinko/components/pinko-table/types.js";
import { FilterItem } from "@incinity/pinko/components/pinko-filter/types.js";
import { OfferForm } from "../offer-form/offer-form.js";
import { PinkoConfirm } from "@incinity/pinko/components/pinko-confirm/pinko-confirm.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoBrowser, WIDTH_DATE_TIME, WIDTH_NAME, WIDTH_NUMBER, WIDTH_PRICE, WIDTH_TITLE } from "@incinity/pinko/components/pinko-browser/pinko-browser.js";
import { NumberHelper } from "@incinity/hiyo/number-helper.js";
import { PinkoPaging } from "../../../@incinity/pinko/components/pinko-paging/pinko-paging.js";
import { TemplatePreview } from "../../common/template-preview/template-preview.js";

const STATUS_COLOR = {
    "Draft": "Blue",
    "Sent": "Yellow",
    "Lost": "Red",
    "Won": "Green",
}

@component()
export class OfferBrowser extends PinkoBrowser<IncinetContext> {

    public onAttach(): void {
        // Set options before render and with already passed context
        this.options = {
            http: this.context.api.http,
            url: `${this.context.api.options.host}/api/offers`
        }
    }

    public createFilter() {
        // Create filter
        this.filter = new PinkoFilter(this.context, {
            title: "components.OfferBrowser.title",
            path: ["Company", "Offers"],
            items: [
                {
                    name: "Add",
                    icon: "Add",
                },
                {
                    name: "Reload",
                    icon: "Reload",
                }
            ],
            selects: [
                new PinkoSelect(this.context, {
                    type: "Tag",
                    name: "status",
                    placeholder: "labels.status",
                    enums: "OfferStatus",
                    value: { "Draft": "Draft" },
                    autosubmit: true
                })
            ],
            resolver: async (term: string): Promise<SearchItem[]> => {
                // Find offers
                let offers = await this.context.api.getResource(`offers?search=${term}`);

                // Return as items
                return offers.data?.map(x => ({
                    name: x.title,
                    label: `${x.number} ${x.title} ${x.partner.name}`.replace(new RegExp(`(${term})`, "gi"), "<strong>$1</strong>"),
                    data: x
                }));
            }
        });

        // Item selected
        this.filter.onItemSelect = async (item: FilterItem) => {
            // Reload?
            if (item.name == "Reload") {
                await this.load();
            }

            // Add?
            if (item.name == "Add") {
                this.showForm();
            }
        }
    }

    public createTable() {
        // Create table
        this.table = new PinkoTable(this.context, {
            type: "SingleSelect",
            size: "Short",
            height: "100%",
            rows: {
                id: "_id",
                route: "/offers/:id",
                decorator: (data: any): string => {
                    return data.disabled ? "disabled" : null;
                }
            },
            columns: [
                {
                    name: "number",
                    type: "String",
                    property: "number",
                    label: "columns.number",
                    formatter: (value: any, data: any) => {
                        return `<div class="cell"><pinko-symbol color="${STATUS_COLOR[data?.status]}" value="${data?.status?.charAt(0)}" label="${data?.number}"></pinko-symbol></div>`
                    },
                    width: WIDTH_NUMBER,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "title",
                    type: "String",
                    property: "title",
                    label: "columns.title",
                    width: WIDTH_TITLE,
                    ellipsis: true,
                    sortable: true,
                    selected: true
                },
                {
                    name: "price",
                    type: "Number",
                    property: "summary.partnerTotal",
                    label: "columns.price",
                    formatter: (value: any, data: any) => {
                        return `<div class="cell cell-right">${NumberHelper.toNumber(value)} EUR</div>`
                    },
                    width: WIDTH_PRICE,
                    ellipsis: true,
                    sortable: true,
                    selected: true
                },
                {
                    name: "partner",
                    type: "String",
                    property: "partner.name",
                    label: "columns.partner",
                    width: WIDTH_NAME,
                    ellipsis: true,
                    sortable: true
                },
                {
                    name: "days",
                    type: "String",
                    property: "created.timestamp",
                    label: "columns.days",
                    formatter: (value: any, data: any) => {
                        return `<div class="cell">${NumberHelper.toNumber(Math.round((Date.now() - new Date(value).getTime())  / 86400000))}</div>`
                    },
                    minWidth: 80,
                    sortable: true
                }
            ],
            actions: [
                {
                    name: "Edit",
                    label: "labels.edit",
                    route: "/offers/:id/edit"
                },
                {
                    name: "Download",
                    label: "labels.download",
                    route: "/offers/:id/download",
                    quick: true
                },
                {
                    name: "Print",
                    label: "labels.print",
                    route: "/offers/:id/print",
                },
                {
                    name: "Delete",
                    label: "labels.delete",
                    escalated: true
                }
            ]
        });

        // Action selected
        this.table.onActionSelect = async (row: TableRow, action: TableAction) => {
            // Edit?
            if (action.name == "Edit") {
                this.showForm(row.data);
            }

            // Detail?
            if (action.name == "Detail") {
                await this.showDetail(row.data);
            }

            // Detail?
            if (action.name == "Download") {
                this.showTemplatePreview(row.data);
            }

            // Print?
            if (action.name == "Print") {
                this.showTemplatePreview(row.data);
            }

            // Delete?
            if (action.name == "Delete") {
                this.showDelete(row.data);
            }
        }
    }

    public createPaging(): void {
        // Create paging
        this.paging = new PinkoPaging(this.context, {
            summarizer: (data: any[]) => {
                // Get sum
                let sum = data?.length ? data.map(x => x.summary.partnerTotal).reduce((a, b) => a + b, 0) : 0;

                // Return sum in EUR
                return `Total of ${NumberHelper.toNumber(sum)} EUR`;
            }
        });
    }

    public createPreview(): void {
        // Create preview
        this.preview = new OfferPreview(this.context);
    }

    /*public createDetail() {
        // Create detail
        this.detail = new OfferDetail(this.context);
    }*/

    public showDelete(data: any): void {
        // Create confirm dialog
        let confirm = new PinkoConfirm(this.context, {
            title: "Really delete?",
            text: "Are you sure you want to delete?",
            labelConfirm: "labels.delete",
            escalated: true
        });

        // Delete on confirm
        confirm.onConfirm = async () => {
            // Lock component
            this.lock();

            // API call
            await this.context.api.deleteResource(`offers/${data._id}`);

            // Unlock component
            this.unlock();

            // Close preview if open
            if (this.preview?.options.data._id == data._id) {
                this.preview.remove();
            }

            // Reload self
            await this.load();
        }

        // Show modal
        confirm.showModal();
    }

    public showForm(data?: any): void {
        // Create dialog
        let form = new OfferForm(this.context, {
            data: {
                ...data,
                partner: data?.partner ? { [data.partner._id]: data.partner.name } : null
            }
        });

        // Reload table on submit
        form.onSubmit = async () => {
            // Reload self
            await this.load();

            // Reload preview
            if (this.preview.isConnected && this.preview?.options.data?._id == data?._id) {
                await this.preview.reload();
            }
        }

        // Show modal
        form.showModal();
    }

    public showTemplatePreview(data: any): void {
        // Create preview dialog
        let preview = new TemplatePreview(this.context, {
            key: "OfferPdf",
            data: data
        });

        // Show
        preview.showModal();
    }

}