import { Context } from "@incinity/hiyo/context.js";
import { PinkoComponentOptions } from "./types.js";
import { Component } from "@incinity/hiyo/component.js";

const LOADER_START = 1000;
const LOADER_BASE = ".loader-base";

export class PinkoComponent<T extends Context = Context, U extends PinkoComponentOptions = PinkoComponentOptions> extends Component<T, U> {

    public lock() {
        // Call lock
        super.lock();

        // Create new element
        let svg = document.createElement("div");

        // Append to dedicated element or self
        (this.querySelector(LOADER_BASE) || this).appendChild(svg);

        // Replace HTML with SVG loader node
        svg.outerHTML =
            `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 100 100" style="width: 20px; height: 20px; position: absolute; right: 8px; top: 8px; z-index: 999">
                <circle cx="50" cy="50" r="50" fill="#8000F7" fill-opacity="1">
                    <animate attributeName="fill-opacity" values="0;0;0;1;1" dur="${LOADER_START}ms" />
                    <animate attributeName="r" values="0;0;0;50;50" dur="1s" />
                </circle>
                <line fill="none" stroke-linecap="round" stroke="#FFFFFF" stroke-width="8" stroke-miterlimit="10" x1="50" y1="50" x2="85" y2="50">
                    <animate attributeName="opacity" values="0;0;0;0;1" dur="${LOADER_START}ms" />
                    <animateTransform attributeName="transform" dur="1s" type="rotate" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                </line>
                <line fill="none" stroke-linecap="round" stroke="#FFFFFF" stroke-width="8" stroke-miterlimit="10" x1="50" y1="50" x2="50" y2="74">
                    <animate attributeName="opacity" values="0;0;0;0;1" dur="${LOADER_START}ms" />
                    <animateTransform attributeName="transform" dur="15s" type="rotate" from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                </line>
            </svg>`;
    }

    public unlock() {
        // Call unlock
        super.unlock();

        // Remove loader node if it has not been overwritten by render()
        // We must remove only direct children (to not remove nested loaders)
        (this.querySelector(`${LOADER_BASE} > svg`) || this.querySelector(":scope > svg"))?.remove();
    }

}