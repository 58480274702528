import "./pinko-input.scss";
import template from "./pinko-input.hbs";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoInputOptions } from "./types.js";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoField } from "../pinko-field/pinko-field.js";

@component(template)
export class PinkoInput extends PinkoField<Context, PinkoInputOptions> {

    // Event handling methods
    public onKey(key: number): void {};

    public change() {
        // Read value
        this.options.value = this.querySelector<HTMLInputElement>((this.options.format == "Text") ? "textarea" : "input").value;

        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }
    }

    public key(key: number): void {
        // Read value
        this.options.value = this.querySelector<HTMLInputElement>((this.options.format == "Text") ? "textarea" : "input").value;

        // Empty string fix
        if (this.options.value == "") {
            this.options.value = null;
        }

        // Enter
        if (key == 13) {
            this.submit();
            return;
        }

        // ESC
        if (key == 27) {
            // Stop propagation to prevent closing details etc.
            event.stopPropagation();

            // Clear value
            this.setValue(null);
            return;
        }

        // OnKey handler
        this.onKey(key);
    }

}