import "./incinet-menu.scss";
import template from "./incinet-menu.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { Component } from "@incinity/hiyo/component.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoSidebar } from "@incinity/pinko/components/pinko-sidebar/pinko-sidebar.js";
import { SidebarItem } from "@incinity/pinko/components/pinko-sidebar/types.js";
import { StringHelper } from "@incinity/hiyo/string-helper.js";
import { PinkoDropdown } from "@incinity/pinko/components/pinko-dropdown/pinko-dropdown.js";
import { DropdownItem } from "@incinity/pinko/components/pinko-dropdown/types.js";
import { FormDialog } from "../form-dialog/form-dialog.js";
import { PartnerForm } from "../../partners/partner-form/partner-form.js";
import { OfferForm } from "../../offers/offer-form/offer-form.js";
import { IncinetApp } from "../incinet-app/incinet-app.js";
import { OfferBrowser } from "../../offers/offer-browser/offer-browser";
import { Router } from "@incinity/hiyo/router";

const COMPONENT_MAP = {
    "Home": "HomeOverview",
    "Partners": "PartnerBrowser",
    "Offers": "OfferBrowser",
    "Contracts": "ContractBrowser",
    "Delivery": "DeliveryBrowser",
    "Tasks": "TaskBrowser",
    "Users": "UserBrowser",
    "Settings": "SettingsPanel",
}

const DIALOG_NEW_MAP = {
    "NewPartner": PartnerForm,
    "NewOffer": OfferForm
}

@component(template)
export class IncinetMenu extends Component<IncinetContext> {

    public select(name: string, skipHistory?: boolean): void {
        // Iterate all items and select/deselect them
        this.querySelectorAll("div.items div.item").forEach((e: Element) => {
            e.classList.toggle("item-selected", e.classList.contains(`item-${StringHelper.toKebabCase(name)}`));
        });

        // Get component name
        let component = StringHelper.toKebabCase(COMPONENT_MAP[name]);

        // Push history
        if (!skipHistory) {
            Router.pushHistory(`/${StringHelper.toKebabCase(name)}`);
        }

        // Open selected component (Home -> HomeBrowser -> <home-browser>)
        document.body.querySelector("incinet-app > main").innerHTML = `<${component}></${component}>`;
    }

    public logout(): void {
        // Call application logout procedure
        document.querySelector<IncinetApp>("incinet-app").logout();
    }
}