import { query } from "@incinity/hiyo/decorators.js";
import { FormDialogOptions } from "./types.js";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoDialog } from "@incinity/pinko/components/pinko-dialog/pinko-dialog.js";
import { PinkoForm } from "@incinity/pinko/components/pinko-form/pinko-form.js";
import { Log } from "@incinity/hiyo/log.js";

export abstract class FormDialog<T extends FormDialogOptions = FormDialogOptions> extends PinkoDialog<IncinetContext, T> {

    // Child components
    @query("pinko-form") public form: PinkoForm;

    public render(): void {
        // This is an important routine to store and restore data when form is rendered.
        // If data comes from options, it will be used on default entry
        let data = this.form?.getData() || this.options.data;

        // When render is called, all form data fields will be redrawn blank
        super.render();

        // Restore form data
        this.form?.setData(data);
    }

    public async trySubmit(): Promise<any> {
        Log.w(`${this.constructor.name}.trySubmit() not implemented. Forgot to override it?`);
    }

    public async submitWithData(): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Submit form data as result
        super.submit(this.form.getData(true));
    }

    public async submitWithTry(): Promise<void> {
        // Validation
        if (!this.form.validate()) {
            return;
        }

        // Show loader
        this.lock();

        try {
            // Call try implementation
            let result = await this.trySubmit();

            // Submit form
            super.submit(result);
        }
        catch (e) {
            if (e.status == 422) {
                // Parse errors to set field reasons
                for (let error of e.response) {
                    let reason = error.code.charAt(0).toLowerCase() + error.code.slice(1);
                    this.form.setReason(error.field, `validation.${reason}`);
                }
            }
            else {
                console.error(e);
            }
        }

        // Hide loader
        this.unlock();
    }
}