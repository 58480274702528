import { Context } from "@incinity/hiyo/context.js";
import { ApiClient } from "../clients/api-client/api-client.js";
import { User } from "../clients/api-client/types";

export class IncinetContext extends Context {

    // Properties
    public user: User;
    public api: ApiClient;
    public data: any;

    constructor() {
        // Call super constructor
        super();

        // Clear data
        this.data = {};

        // Create API client
        this.api = new ApiClient({
            host: "https://www.incinet.cz"
        });
    }

}

