import "./offer-connector-form.scss";
import template from "./offer-connector-form.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { FormDialog } from "../../common/form-dialog/form-dialog.js";
import { OfferItem } from "../../../clients/api-client/types.js";

@component(template)
export class OfferConnectorForm extends FormDialog {

    // Properties
    public connectors: any[];

    public onAttach(): void {
        // Filter out not selected connectors
        this.connectors = this.context.data.pricelist.connectors.filter(x => !this.options.data.items?.map(y => y.code).includes(x.code));

        // Sort out
        this.connectors.sort((a, b) => a.name.localeCompare(b.name));
    }

    public search(term: string): void {
        // Show or hide matching items
        this.querySelectorAll("pinko-section").forEach((e: HTMLElement) => {
            e.style.display = (term == null || e.textContent.toLowerCase().includes(term.toLowerCase())) ? "block" : "none";
        });

        // Reset scroll position
        //this.querySelector("article").scrollTo({ top: 0 });
    }

    public select(i: number): void {
        // Get module from pricelist
        let module = this.connectors[i];

        // Create offer item from module
        let item: OfferItem = {
            type: "Connector",
            code: module.code,
            name: module.name,
            price: module.price,
            count: 1
        }

        // Submit with module
        this.submit(item);
    }
}