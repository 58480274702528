import "./template-preview.scss";
import template from "./template-preview.hbs";
import { IncinetContext } from "../../../context/incinet-context.js";
import { PinkoDialog } from "@incinity/pinko/components/pinko-dialog/pinko-dialog.js";
import { TemplatePreviewOptions } from "./types.js";
import { component } from "@incinity/hiyo/decorators.js";
import { Log } from "@incinity/hiyo/log.js";
import Handlebars from "handlebars";


@component(template, true)
export class TemplatePreview extends PinkoDialog<IncinetContext, TemplatePreviewOptions> {

    // Properties
    public html: string;

    public onRender() {
        // Iframe element
        let iframe = this.querySelector<HTMLIFrameElement>("iframe");

        // Set content
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(this.html);
        iframe.contentWindow.document.close();
    }

    public print(): void {
        // Print content of iframe element only
        this.querySelector<HTMLIFrameElement>("iframe")?.contentWindow.print();
    }

    public async load(): Promise<void> {
        // Load template
        let template = await this.context.api.getResource(`templates/${this.options.key}`);

        // Not template?
        if (!template) {
            Log.w(`${this.constructor.name}: Missing template with key ${this.options.key}`);
            return;
        }

        // Data
        let data = {
            ...this.options.data,
            // TODO: anything else to put into template context?
        };

        // Get HTML from template
        //let t = Handlebars.compile(template.value);
        this.html = Handlebars.compile(template.content)(data);

        console.info(data);
    }
}