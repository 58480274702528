import "./pinko-detail.scss";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoDetailOptions } from "./types.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { PinkoOverlay } from "../pinko-overlay/pinko-overlay.js";

export abstract class PinkoDetail<T extends Context = Context, U extends PinkoDetailOptions = PinkoDetailOptions> extends PinkoComponent<T, U> {

    public showModal(): void {
        // Add self to overlay and then to main page
        this.appendTo(new PinkoOverlay().appendTo(document.body));
    }

    public remove(): void {
        // Close via overlay if displayed
        if (this.parentComponent instanceof PinkoOverlay) {
            // Close self via overlay
            this.parentComponent.remove();
        }
        // Else remove self directly from DOM
        else {
            super.remove();
        }
    }

}