import "./pinko-button.scss";
import template from "./pinko-button.hbs";
import { component } from "@incinity/hiyo/decorators.js";
import { PinkoComponent } from "../pinko-component/pinko-component.js";
import { Context } from "@incinity/hiyo/context.js";
import { PinkoButtonOptions } from "./types.js";

@component(template)
export class PinkoButton extends PinkoComponent<Context, PinkoButtonOptions> {

    // Event handling methods
    public onClick(): void {}; // Constructor was called

    public click(): void {
        // OnClick handler
        this.onClick();
    }

}